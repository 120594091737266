import styled from '@emotion/styled'
import { LocalizedGatsbyLink } from '@/components/LocalizedGatsbyLink'

import { mediaMax } from '@/styles/mixins'

export const Container = styled(LocalizedGatsbyLink)`
  width: calc((100% - 4rem) / 3);
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-decoration: none;
  color: inherit;

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${mediaMax.sm} {
    width: calc((100% - 2rem) / 2);
    margin-right: ${({ theme }) => theme.spacing.xsmall};

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  ${mediaMax.xs} {
    width: 100%;
    margin-right: 0;
  }
`

export const Packshot = styled.div`
  padding-bottom: 130%;
  position: relative;
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Title = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2rem;
  padding: 2rem 0 1rem;
`

export const Price = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 1.4rem;
`
