import React from 'react'

import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Packshot,
  Image,
  Title,
  Price
} from './style'

function ProductsGridItem ({
  title = '',
  price = '',
  url = '',
  packshot
}) {
  return (
    <Container to={ url }>
      <Packshot>
        <Image alt={ packshot?.alt } loading='lazy' src={ resolveAssetURL(packshot?.url) } />
      </Packshot>
      <Title>{ title }</Title>
      {price && (
        <Price>{ price }</Price>
      )}
    </Container>
  )
}

export default ProductsGridItem
