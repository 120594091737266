import styled from '@emotion/styled'

import {
  mediaMax,
  sectionTitle,
  sectionSubtitle
} from '@/styles/mixins'

import { GridContainer } from '@/components/Grid'

export const Container = styled(GridContainer)`
  padding: 6rem 0 2rem;
`

export const Introduction = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  ${mediaMax.xs} {
    width: 100%;
    margin-top: 3rem;
  }
`

export const Subtitle = styled.span`
  ${sectionSubtitle};
  color: ${({ theme }) => theme.colors.green};
  white-space: nowrap;
  margin-bottom: 2rem;
`

export const Title = styled.h1`
  ${sectionTitle};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.green};
  font-size: 4rem;
`

export const Text = styled.div`
  color: ${({ theme }) => `${theme.colors.brown}`};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: .1rem;
  margin-bottom: 5rem;

  ${mediaMax.xs} {
    margin-bottom: 3rem;
  }
`

export const ProductsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
